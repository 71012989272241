import { ParsedUrlQuery } from "querystring";
import { GetServerSidePropsContext } from "next";
import PageWrapper from "components/global/PageWrapper/PageWrapper";
import Head from "components/global/Head/Head";
import { getPageProps, SEOData } from "utils/cms/pageHelper";
import RewardsContainer from "containers/Rewards/Rewards";
interface Props {
  finalSEO?: SEOData;
}

const defaultSEO = {
  title: "Sign Up for Rewards & Discount Offers - Zaxbys",
  description: "Zaxbys account information page. Sign up or login to view.",
};

export const getServerSideProps = async (context: GetServerSidePropsContext<ParsedUrlQuery>) => {
  return getPageProps(context, defaultSEO);
};

const Rewards = ({ finalSEO }: Props) => {
  const ogURL = process.env.NEXT_PUBLIC_HOST + "/rewards";
  return (
    <PageWrapper>
      <Head {...finalSEO} ogURL={ogURL} />
      <RewardsContainer />
    </PageWrapper>
  );
};

export default Rewards;
